
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { ManageUsersSetup } from './ManageUsersSetup';
import { ManageUsersModalSetup } from './ManageUsersModalSetup';
import { ManageUsersAsideSetup } from './ManageUsersAsideSetup';
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import AgGridVerifiedBadge from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridVerifiedBadge.vue";
import AgGridEditButton from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridEditButton.vue";
// import MessageBox from "@/components/notifications/BaseMessageBox.vue";
import KTNewUserModal from "@/components/modals/forms/NewFormModal.vue";
import KTEditUserAside from "@/layout/aside/EditAside.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";


export default defineComponent({
  name: 'ManageUsers',
  components: {
    AgGridFP,
    // MessageBox,
    KTNewUserModal,
    KTEditUserAside,
    ErrorMessage,
    Field,
    Multiselect
  },
  setup() {
    const frameworkComponents = reactive({
      AgGridVerifiedBadge,
      AgGridEditButton,
    });
    const {
      usersArrayData,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      setGridApi,
      setColApi,
      rowValueChanged,
      cellValueChanged,
      deleteItem,
      applyState,
      handleSaveState,
      testData,
      selectedUser,
      showPassword
    } = ManageUsersSetup();

    const {
      userRoles,
      asideValidationSchema,
      saveEdit,
      handleClose,
      multiSelectMsg,
    } = ManageUsersAsideSetup(selectedUser);

    const {
      newUserData,
      modalValidationSchema,
      onSubmitUser,
      submitButtonRef,
      loading,
      handleReset
    } = ManageUsersModalSetup();

    return {
      selectedUser,
      usersArrayData,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      setGridApi,
      setColApi,
      rowValueChanged,
      cellValueChanged,
      deleteItem,
      applyState,
      handleSaveState,
      testData,
      frameworkComponents,
      newUserData,
      modalValidationSchema,
      onSubmitUser,
      submitButtonRef,
      loading,
      saveEdit,
      userRoles,
      asideValidationSchema,
      handleClose,
      multiSelectMsg,
      handleReset,
      showPassword

    }
  }
})
